
import { useEffect, useState } from 'react';

const useBackgroundImage = (imageUrl) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!imageUrl) return;

    const img = new Image();
    img.src = imageUrl;
    img.onload = () => setLoading(false);
    img.onerror = () => setLoading(false); // Handle error by stopping loader

    return () => {
      // Cleanup if component unmounts
      img.onload = null;
      img.onerror = null;
    };
  }, [imageUrl]);

  return loading;
};

export default useBackgroundImage;


