import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./accordion.css";
import { Box } from "@mui/material";
import useInView from "../../hooks/Lazyloader";

const FAQAccordion = ({ ticketInfo, accordionPanel, expanded, onChange }) => {
  const windowWidth = document.documentElement.clientWidth;

  const [ref, isInView] = useInView({
    threshold: 0.1, // Adjust this value as needed
    triggerOnce: true, // This ensures the card renders only once when it comes into view
  });
  const [shouldRender, setShouldRender] = useState(false);
  useEffect(() => {
    if (isInView) {
      setShouldRender(true);
    }
  }, [isInView]);

  if (!shouldRender) {
    return <div ref={ref} style={{ height: windowWidth<720?"40px": "76px", width: "100%" }} />; // Placeholder
  }

  
  return (
    <Box className="accordionContainer" sx={
      {
        animation: 'slideUp 0.5s ease-out forwards',
        '@keyframes slideUp': {
          '0%': {
            transform: 'translateY(150%)',
            opacity: 0.5,
          },
          '100%': {
            transform: 'translateY(0)',
            opacity: 1,
          },
        },
      }
    }>
      <Accordion
        expanded={expanded === accordionPanel}
        onChange={onChange(accordionPanel)}
        style={{
          backgroundColor: "#FFE8C5",
          color: "black",
          border: "1px solid #390C06",
        }}
        className="mainAccordion"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="accordionIcon" style={{ color: 'black' }} />}
          sx={{
            borderBottom: expanded === accordionPanel ? "1px solid #390C06" : 'none',
          }}
        >
          <Typography
            fontFamily="Texturina"
            fontWeight="semibold"
            textAlign="left"
            sx={{
              fontSize: {
                xs: "12px",
                sm: "18px",
                md: "18px",
                lg: "24px",
              },
              lineHeight: {
                xs: "16px",
                sm: "22px",
                md: "22px",
                lg: "28px",
              },
            }}
          >
            {ticketInfo?.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails 
         
        >
          <ul>
            {ticketInfo?.points?.map((point, index) => (
              <li key={index} dangerouslySetInnerHTML={{ __html: point }} className="pointList" />
            ))}
          </ul>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default FAQAccordion;