import React, { useEffect } from "react";
import Header from "../../common/Header";
import { Box, Typography, Grid, useMediaQuery, Button } from "@mui/material";
import { useState } from "react";
// import "../../styles/globalStyles.css";
import AlertMessage from "../../common/AlertMessage";
// import "../../styles/stayupdated.css";
import Footer from "../../common/Footer";
// import TextBanner from "../../images/Home/Text Banner.png";
// import RegisterBanner from "../../images/stayupdated Banner.svg";
// import MidBanner2 from "../../images/testBanner.svg";
import { Token } from "../../common/Credential/credential";
import theme from "../../theme";
import { Helmet } from "react-helmet";
import clevertap from "clevertap-web-sdk";
import { Sponsors } from "../../common/Footer/sponsors";
import SideCurtains from "../../common/Header/sidecurtain";
import "./register.css";
import StyledTypography from "../../font/StyledTExt";
// import { google } from 'googleapis';

// Set up the Google Sheets API client using your credentials
// const sheets = google.sheets('v4');
// const sheetsAuth = new google.auth.GoogleAuth({
//     keyFile: '../../../googleSheetSecret.json',
//     scopes: ['https://www.googleapis.com/auth/spreadsheets'],
// });

const Register = () => {
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const isSmallScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const windowWidth = document.documentElement.clientWidth;

  if (/(iPhone|iPad)/i.test(navigator.userAgent)) {
    document.documentElement.className += " is-iphone";
  } else if (/(Android)/i.test(navigator.userAgent)) {
    document.documentElement.className += " is-android";
  }

  // Function to update the screen height
  const updateScreenHeight = () => {
    setScreenHeight(window.innerHeight);
    console.log("window:", window.innerHeight);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    updateScreenHeight();
    window.addEventListener("resize", updateScreenHeight);
    return () => {
      window.removeEventListener("resize", updateScreenHeight);
    };
  }, []);

  useEffect(() => {
    //GTM
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "pageview",
      event_action: "view",
      name: "registration_page",
    });
  }, []);

  const [formValues, setFormValues] = useState({
    name: "",
    phone: "",
    email: "",
    city: "",
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const handleTextInputChange = (e) => {
    const { name, value } = e.target;
    if ((name === "name" || name === "city") && !/^[A-Za-z\s]*$/.test(value)) {
      setSnackbar({
        open: true,
        message: "Name should only contain alphabets.",
        severity: "error",
      });
      return;
    }

    setFormValues({ ...formValues, [name]: value });
  };

  const isValid = (data) => {
    if (
      data.name.trim() !== "" &&
      data.primary_email.trim() !== "" &&
      data.city.trim() !== "" &&
      data.primary_phoneNo.trim !== ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  // mobile no format +91-XXXXXXXXXX
  const isMobileNoValid = (mobileNumber) => {
    const pattern = /^\+91-\d{10}$/;
    if (!pattern.test(mobileNumber)) {
      setSnackbar({
        open: true,
        message: "Mobile No should be in +91-XXXXXXXXXX Format",
        severity: "error",
      });
      return false;
    } else {
      return true;
    }
  };

  const handleRegisterNow = async (e) => {
    e.preventDefault();

    //API
    const data = {
      name: formValues?.name,
      primary_phoneNo: `+91-${formValues?.phone}`,
      primary_email: formValues?.email,
      city: formValues?.city,
    };

    if (isValid(data)) {
      if (isMobileNoValid(data.primary_phoneNo)) {
        try {
          const myHeaders = new Headers();
          myHeaders.append(
            "Authorization",
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiYWQzYWViM2MtMTAxNS00NzBiLWFkZTYtYzJiNzJiMjg0ZWFhIiwiaWF0IjoxNzIyOTQyMDY4LCJleHAiOjMxNzI2NzM4NDQ2OH0.wFQMQEPfnzXvcJ9Bt_UJM0L4E5c2DpCJJ6pdPnyWJ8E"
          );
          myHeaders.append("Content-Type", "application/json");

          const formData = JSON.stringify(data);

          const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formData,
            redirect: "follow",
          };

          fetch(
            "https://gratify-rewards.esmagico.net/api/bandland/register-user",
            requestOptions
          )
            .then((response) => response.json())
            .then((response) => {
              console.log("response:", response);
              if (response) {
              
                setSnackbar({
                  open: true,
                  message: "Rock on, partner. Welcome to the fold!",
                  severity: "success",
                });
                setFormValues({
                  name: "",
                  phone: "",
                  email: "",
                  city: "",
                });
              } else {
                setSnackbar({
                  open: true,
                  message: "Uh-oh. Could you try that again?",
                  severity: "error",
                });
              }
            });
        } catch (error) {
          setSnackbar({
            open: true,
            message: "Uh-oh. Could you try that again?",
            severity: "error",
          });
        }
      }
    } else {
      setSnackbar({
        open: true,
        message: "All Fields are required!",
        severity: "error",
      });
    }
    // Get the values from the form

    // // save data to sheet
    // const name = formValues?.name;
    // const phone = formValues?.phone;
    // const email = formValues?.email;
    // const city = formValues?.city;

    // // Create the data to be added to the Google Sheet
    // const rowData = [name, phone, email, city];

    // if (isValid(data)) {
    //     if (isMobileNoValid(data.primary_phoneNo)) {
    //         try {
    //             // Authorize and create a connection to the Google Sheets API
    //             const auth = await sheetsAuth.getClient();
    //             const sheetsAPI = google.sheets({ version: 'v4', auth });

    //             // Define the spreadsheet ID and range
    //             const spreadsheetId = '18ir2S9KbnvsO8ks82GIS2yHSUaIs55PfJ7R8czixlnU';
    //             const range = 'A2:D2'; // Adjust the range as needed

    //             // Append the data to the Google Sheet
    //             const response = await sheetsAPI.spreadsheets.values.append({
    //                 spreadsheetId,
    //                 range,
    //                 valueInputOption: 'RAW',
    //                 resource: {
    //                     values: [rowData],
    //                 },
    //             });

    //             setSnackbar({
    //                 open: true,
    //                 message: "You response has be recorded successfully!",
    //                 severity: "success",
    //             });
    //             setFormValues({
    //                 name: "",
    //                 phone: "+91-",
    //                 email: "",
    //                 city: "",
    //             });
    //         } catch (error) {
    //             console.error('Error adding data to Google Sheet:', error);
    //             setSnackbar({
    //                 open: true,
    //                 message: "Something went wrong!",
    //                 severity: "error",
    //             });
    //         }
    //     }
    // } else {
    //     setSnackbar({
    //         open: true,
    //         message: "All Fields are required!",
    //         severity: "error",
    //     });
    // }
    // GTM
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "click",
      event_action: "registration_submit",
    });

    // clevertap
    clevertap.event.push("Bandland Register Submit", {
      name: formValues?.name,
      email: formValues?.email,
      phoneno: formValues?.phone,
      city: formValues?.city,
    });
  };

  const pageContent = (
    <Box
      // position="absolute"
      sx={
        {
          // marginTop: {
          //   xs: "22%",
          //   sm: "25%",
          //   md: "22%",
          //   lg: "22%",
          //   xl: "22%",
          //   xll: "22%",
          // },
        }
      }
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      width="100%"
    >
      <SideCurtains
        leftImageSrc="https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/remaining/bushesleft/stayupdated.webp"
        rightImageSrc="https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/remaining/bushesright/stayupdated.webp"
        mobileleft={
          "https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/remaining/stayupdatedleft.webp"
        }
        mobileright={
          "https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/remaining/stayupdatedright.webp"
        }
      />
       <StyledTypography
        fontFamily="modesto-condensed"
        className="text-shadow text-stroke"
        sx={{
          fontSize: {
            xs: "30px",
            sm: "38px",
            md: "48px",
            lg: "56px",
            xl: "56px",
            xll: "58px",
          },
          width: {
            xs: "279px",
            sm: "500px",
            md: "800px",
            lg: "913px",
            xl: "913px",
            xll: "940px",
          },
          paddingBottom: {
            xs: "10px",
            sm: "8px",
            md: "4px",
            lg: "1px",
            xl: "1px",
            xll: "1px",
          },
          paddingTop: {
            xs: "42px",
            sm: "50px",
            md: "60px",
            lg: "72px",
            xl: "72px",
            xll: "76px",
          },
          zIndex: 2,
        }}
      >
        DON'T MISS OUT!
        
      </StyledTypography>

      <StyledTypography
        fontFamily="texturina"
        className="font-texturina"
        sx={{
          fontSize: {
            xs: "12px",
            sm: "18px",
            md: "24px",
            lg: "30px",
            xl: "30px",
            xll: "33px",
          },
          width: {
            xs: "70%",
            sm: "70%",
            md: "60%",
            lg: "60%",
            xl: "60%",
            xll: "60%",
          },
          paddingBottom: {
            xs: "25px",
            sm: "45px",
            md: "65px",
            lg: "108px",
            xl: "108px",
            xll: "120px",
          },
        }}
      >
       Get updates on exclusive deals, lineup announcement, pre-parties, merch & much more - right in your inbox!
      </StyledTypography>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: {
            xs: "289px",
            sm: "400px",
            md: "500px",
            lg: "630px",
            xl: "630px",
          },
        }}
      >
        {/* <Box border="2px solid  blue"> */}

        {/* </Box> */}
        <Box
  component="form"
  sx={{
    margin: "auto",
    maxWidth: "500px",
    padding: {
      xs: "23px 19px 14px", // for mobile screens (xs, sm)
      md: "50px 40px 34px", // for screens above 720px
    },
    backgroundColor: "#FFE8C5",
    border: "2px solid #000",
    borderRadius: "15px",
    width: {
      xs: "289px",
      sm: "400px",
      md: "500px",
      lg: "630px",
      xl: "630px",
    },
  }}
  onSubmit={handleRegisterNow}
>
  <Grid
    container
    spacing={{ xs: "11px", md: "16px", lg: "24px", xl: "24px" }}
  >
    {[
      { label: "Name:", name: "name", prefix: "" },
      { label: "Phone:", name: "phone", prefix: "+91-" },
      { label: "E-mail ID:", name: "email", prefix: "" },
      { label: "City:", name: "city", prefix: "" },
    ].map((field) => (
      <Grid item xs={12} key={field.name}>
        <Box sx={{ display: "flex", border: "1px solid #000" }}>
          <Typography
            sx={{
              width: "30%",
              padding: {
                xs: "4px",
                sm: "6px",
                md: "8px",
                lg: "8px",
                xl: "8px",
              },
              backgroundColor: "white",
              borderRight: "1px solid #000",
              fontFamily: "texturina",
              fontSize: {
                xs: "12px",
                sm: "18px",
                md: "24px",
              },
            }}
          >
            {field.label}
          </Typography>
          <Box
            sx={{
              display: "flex",
              width: "70%",
              backgroundColor: "white",
            }}
          >
            {field.name === "phone" ? (
              <>
                <input
                  style={{
                    width: "45px",
                    border: "none",
                    outline: "none",
                    padding: "2%",
                    fontFamily: "texturina",
                    backgroundColor: "white",
                  }}
                   className="register-input"
                  value={field.prefix}
                  readOnly
                />
                <input
                  style={{
                    width: "calc(100% - 45px)",
                    border: "none",
                    outline: "none",
                    padding: "2%",
                    fontFamily: "texturina",
                  }}
                  name={field.name}
                  type="text"
                  value={formValues?.[field.name] || ""}
                  onChange={handleTextInputChange}
                  maxLength={10} // Maximum length for the phone number itself
                  className="register-input"
                />
              </>
            ) : (
              <input
                style={{
                  width: "100%",
                  border: "none",
                  outline: "none",
                  padding: "2%",
                  fontFamily: "texturina",
                }}
                name={field.name}
                type={field.name === "email" ? "email" : "text"}
                value={formValues?.[field.name] || ""}
                onChange={handleTextInputChange}
                maxLength={40}
                className="register-input"
              />
            )}
          </Box>
        </Box>
      </Grid>
    ))}
    <Grid
      item
      xs={12}
      sx={{
        textAlign: "center",
        marginTop: { xs: "15px", sm: "18px", md: "20px", lg: "45px" },
      }}
    >
      <Button
        type="submit"
        sx={{
          backgroundColor: "#F08A53",
          border: "1px solid",
          // Border color
          "&:hover": {
            backgroundColor: "#d07843", // Hover background color
            borderColor: "#390C06", // Keep border color on hover
          },
          color: "white",
          fontFamily: "texturina-semibold",
          fontSize: {
            xs: "12px",
            sm: "18px",
            md: "24px",
          },
          padding: "10px 30px",
          borderRadius: "25px",
          borderColor: "#390C06",
        }}
      >
        REGISTER
      </Button>
    </Grid>
  </Grid>
</Box>

      </Box>
      <Box
        position="relative"
        bottom="0"
        sx={{
          mt: {
            xs: -2,
            sm: 2,
            md: 2,
            lg: 2,
            xl: 2,
            xll: 2,
            xxl:
              screenHeight <= 900
                ? "100vh"
                : screenHeight <= 1100
                ? "90vh"
                : screenHeight <= 1400
                ? "75vh"
                : 60,
          },
        }}
      ></Box>
      <AlertMessage snackbar={snackbar} setSnackbar={setSnackbar} />

      <Sponsors
        svgSrc={
          "https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/finalChanges/registerSponsors.png"
        }
        mt={0}
        mb={5}
      />

      <Footer />
    </Box>
  );

  return (
    <>
      <Helmet>

      <meta
      property="og:title"
      content="Bandland 2024 Stay Update Page"
    />
    <meta
      property="og:description"
      content="Bandland 2024 is a rock festival in Bangalore, India. It will be a 2-day event that will relive the Golden Era of rock music. Register now to secure your spot!"
    /> 
   {/* https://www.bandland.in/#/contact-us */}
   <link rel="canonical" href="https://www.bandland.in/#/contact-us" />
     <meta
      name="title"
      content="Bandland 2024 Stay Update Page"
    />
        <title>Bandland 2024 Stay Update Page</title>
        <meta
          name="description"
          content="Bandland 2024 is a rock festival in Bangalore, India. It will be a 2-day event that will relive the Golden Era of rock music. Register now to secure your spot!"
        />
        <meta
          name="keywords"
          content="Bandland, Bandland Bangalore, Bandland 2024, Bandland 2024 tickets, Bandland tickets, Bandland meaning, Bandland festival, Bandland registration"
        />
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NNK87PBS');
                    `}
        </script>
        <script data-react-helmet="true" type="application/ld+json">
{
  JSON.stringify({
    "@context": "http://schema.org",
    "@type": "Organization",
    "url": "https://www.bandland.in/",
    "logo": "https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/mobile/common/footerLogo.web",
    "name": "Bandland",
    "sameAs": [

                 
      "https://www.facebook.com/Bandland.in/",
      "https://twitter.com/BandlandIndia/",
      "https://www.youtube.com/@BandlandIndia ",
     "https://www.instagram.com/bandland.in/"
    ],
    "contactPoint": [{
      "@type": "ContactPoint",
      "emaildId": "CustomerExperienceForce@bookmyshow.com",
      "contactType": "customer service",
      "areaServed": "IN",
      "availableLanguage": ["English", "Hindi", "Marathi", "Tamil", "Telugu", "Kannada", "Gujarati", "Punjabi"]
    }] 
  })
}
</script>

<script data-react-helmet="true" type="application/ld+json">
{
  JSON.stringify({
    "@context": "http://schema.org",
    "@type": "WebSite",
    "name": "BookMyShow",
    "url": "https://www.bandland.in/"
  })
}
</script>
{/* breadcrumnb */}

<script type="application/ld+json">
{
  JSON.stringify({
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": "https://example.com/dresses",
          "name": "Dresses"
        }
      },
      {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@id": "https://example.com/dresses/real",
          "name": "Real Dresses"
        }
      }
    ]
  })
}
</script>

        <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NNK87PBS"
                    height="0" width="0" style="display:none;visibility:hidden"></iframe>`}</noscript>
      </Helmet>
      <Header pageContent={pageContent} />
    </>
  );
};

export default Register;
