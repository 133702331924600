import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import useInView from "../../hooks/Lazyloader";

export const Sponsors = ({
  svgSrc,
  alt = "Footer decoration",
  mb = 6,
  mt = 27,
}) => {
  const [ref, isInView] = useInView({
    threshold: 0.1, // Adjust this value as needed
    triggerOnce: true, // This ensures the card renders only once when it comes into view
  });
  const [shouldRender, setShouldRender] = useState(false);
  useEffect(() => {
    if (isInView) {
      setShouldRender(true);
    }
  }, [isInView]);

  if (!shouldRender) {
    return <div ref={ref} style={{ height: "380px", width: "100%" }} />; // Placeholder
  }

  return (
    <Box
      component="img"
      src={svgSrc}
      alt={alt}

      sx={{
        width: "100%",
        height: "auto",
        marginTop: `-${mt}vw`,
 pointerEvents: "none",
        display: "block",
        marginBottom: `-${mb}%`, // This ensures no gap between SVG and footer
        verticalAlign: "bottom", // Removes any extra space below the image
        animation: 'slideUp 1s ease-out forwards',
        '@keyframes slideUp': {
          '0%': {
            transform: 'translateY(100%)',
            opacity: 0,
          },
          '100%': {
            transform: 'translateY(0)',
            opacity: 1,
          },
        },
      }}
    />
  );
};
