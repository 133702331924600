import React, { useEffect, useState } from "react";
// import "../../styles/Home.css";
// import "./ticket.css"
// import TextBanner from "../../images/Home/Text Banner.png";
import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
// import OrangeBand from "../../images/Home/Orange Band2.png";
// import Footer from "../../common/Footer";
import Header from "../../common/Header";
// import gogoDollBanner from "../../images/gogoDollBanner.svg";
import Footer from "../../common/Footer/index";
import { useNavigate } from "react-router-dom";
// import Banner from "../../images/faqBanner1.png";
import FAQAccordion from "../../common/FAQAccordion/FAQAccordion";
import { questions } from "../../common/FAQQuestions/question";
import { Helmet } from "react-helmet";
import FAQAccordian from "../../common/FAQAccordion/FAQAccordion";
import SideCurtains from "../../common/Header/sidecurtain";
import { Sponsors } from "../../common/Footer/sponsors";
import StyledTypography from "../../font/StyledTExt";

const GeneralInfoSection = ({ title, faqs, marginTop = {} }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box>
      <StyledTypography
        fontFamily="modesto-expanded"
        sx={{
          mt: { marginTop },
          fontSize: {
            xs: "22px",
            sm: "24px",
            md: "30px",
            lg: "48px",
            xl: "48px",
            xll: "56px",
          },
        }}
      >
        {title}
      </StyledTypography>

      {faqs.map((faq, index) => (
        <Box
          key={index}
          sx={{
            mb: {
              xs: "10px",
              sm: "20px",
              md: "30px",
              lg: "41px",
              xl: "41px",
            },
          }}
        >
          <FAQAccordian
            key={index}
            ticketInfo={faq}
            accordionPanel={`panel${index}`}
            expanded={expanded}
            onChange={handleChange}
            floatAbove={false} // Adjust as needed
          />
        </Box>
      ))}
    </Box>
  );
};
const Generalquery = [
  {
    title: "When is the event taking place?",
    points: [
      "Bandland will take place on November 23rd and 24th at Nice Grounds, BIEC, Bengaluru.",
    ],
  },
  {
    title: "What is the duration of the festival?",
    points: [
      "The festival is a two-day festival, with 8-9 hours of performances per day.",
    ],
  },
  {
    title: "How do I get to the venue?",
    points: [
      "Bangalore Metro - The venue BIEC is just 2 kms away from the last stop, Nagasandra, of the phase two (Green Line) of Bangalore Metro.",
      "Kempegowda International Airport is 40 km away from the venue. If you are staying in the heart of the city, then you have the option of taking the airport shuttle buses.",
      "Nearest Bus Stop - Madavara.",
      "Shuttle Buses - Information coming soon.",
      '<a style= "color: black  " href="https://maps.app.goo.gl/7FyB8vBVUs7wrWPy6 " target="_blank">Link here</a> to see google maps link.',
    ],
  },
  {
    title: "Can I drive to the event?",
    points: [
      "Yes, the venue is accessible by road. For patrons above legal drinking age, please have a designated driver in case you choose to come with your personal vehicles.",
    ],
  },
  {
    title: "Is there parking available at the venue?",
    points: [
      "There is limited parking available for The Citizens on a first come first serve basis (Parking Fee - Applicable). Perks & privileges on parking are mentioned in the special category ticket descriptions.",
    ],
  },
];

const ticketInformation = [
 
  {
    title: "How will I get my tickets?",
    points: [
      "Tickets will be home delivered at a date closer to the event - updates for the same will be shared across our social media channels. You can always refer to your digital ticket copy in your BookMyShow transaction history.",
    ],
  },
  {
    title: "Is there a ticket limit?",
    points: [
      "You can buy up to 10 tickets per person. All ticket categories are subject to availability - so get yours before they sell out!",
    ],
  },
  {
    title: "Will the tickets be available for sale on any other platform?",
    points: [
      "No, the official tickets will only be available for sale on BookMyShow. We strongly recommend you not to purchase them via any third party to avoid the possibility of counterfeit tickets.",
    ],
  },
  {
    title: "How can I purchase tickets as an international customer?",
    points: [
      "Use the pin/zip code 123456 to digitally make your purchase outside of India. Collect your wristbands at the Box Office on Nov 23rd & 24th 2024. For more queries, write here!",
    ],
  },
  {
    title: "How do I get a refund if I can't make it?",
    points: [
      "Tickets are not transferable or cancellable. In case of any queries, write to us.",
    ],
  },
  {
    title: "What happens if the event is canceled?",
    points: [
      "You'll be notified in advance via email & phone, with an option to claim a refund or carry it forward for a future date (if rescheduled). Lineups are subject to change in the event of rescheduling.",
    ],
  },
];

const INCLUSION = [

  

  {
    title: "Are children allowed for the show?",
    points: [
      "Kids are absolutely welcome! They need to be accompanied by a guardian, who must hold a ticket on their behalf. Age eligibility details are shared below.",
    ],
  },
  {
    title: "What children's ages are eligible for a ticket?",
    points: [
      "Kids below the ages of 5 are allowed to enter the festival for free. Ages 5-17 will need to hold tickets that are purchased and accompanied by their guardian. They must hold the same ticket category as their guardian.",
    ],
  },
  {
    title: "Will there be F&B available at the venue?",
    points: [
      "Yes, food and beverages will both be available for purchase at the venue. Outside food or beverages will not be permitted.",
    ],
  },
  {
    title: "Is the venue air-conditioned?",
    points: ["No, this is an open-air concert festival."],
  },
  {
    title: "Is this a seated show?",
    points: [
      "No, the festival will be a walk-in standing concert experience. Any seating options will be specified and made available in special category tickets.",
    ],
  },
  {
    title: "Will I be able to switch my ticket to a different category?",
    points: [
      "No, tickets once purchased will not be upgradable or changeable in terms of category.",
    ],
  },
  {
    title: "Is there an all-area-access ticket with an artist meet & greet?",
    points: [
      "There are no all-area-access passes. Perks and privileges on special category tickets are mentioned in the ticket descriptions.",
    ],
  },
  {
    title: "What is included in a VIP / Platinum ticket?",
    points: [
      "Tuborg Zero Soda Rock & Roller : <br/>"+
      "1. VIP admission to both days of the festival. <br/>"+
      "2. Dedicated Tuborg Zero Soda Rock & Roller Zone with elevated deck which has sight of both stages. <br/>"+
      "3. Exclusive F&B Area and toilets in the VIP area. All F&B is purchasable.<br/>"+
      "4. Preferred parking near the venue on a first come first serve basis.<br/>"+
      "5. Dedicated entry lane for fast access into the festival.<br/>"+
      "6. Kids aged 0-5 accompanying VIP ticket holders can enter the festival for free.<br/>"+
      "7. Kids aged 5+ & older (born before Dec 15, 2018) need to buy a VIP ticket.<br/>",
      
      "Ace of Spades:<br/>"+
      "1. Platinum admission to both days of the festival.<br/>"+
      "2. Access to the Ace of Spades Pits on both stages.<br/>"+
      "3. Exclusive Ace of Spades lounge with seating, and complimentary snacks and drinks.<br/>"+
      "4. Exclusive Bars & air conditioned toilets.<br/>"+
      "5. Dedicated entry for fast access into the festival.<br/>"+
      "6. Home-delivered festival kits and free parking (with dedicated space) nearest to the venue.",
    ],
  },
  {
    title: "Do you accommodate people with special needs?",
    points: [
      `The festival is wheelchair-friendly and our crew will be happy to assist anyone with special needs. For more queries, <a style= "color: black " href="mailto:CustomerExperienceForce@bookmyshow.com" target="_blank">write to us</a>. We encourage attendees to bring their own wheelchairs; one will be provided at the festival only in the case of any on-ground medical emergencies.`,
    ],
  },
];
const doAndDont = [
  {
    title: "Do I need to be vaccinated to get into the venue?",
    points: [
      "In the best interest of fans and staff, we encourage everyone to follow COVID-19 protocols mandated by local governments. By purchasing tickets to this event, you agree to abide by the health and safety measures in effect at the time of the event, which may include, but are not limited to, wearing masks, providing proof of double vaccination status and/or providing proof of a negative COVID-19 test.",
    ],
  },
  {
    title: "Is smoking allowed at the show?",
    points: [
      "Smoking is permitted only in designated zones. Patrons are encouraged to dispose of their cigarette waste responsibly using the bins/pouches provided.",
    ],
  },
  {
    title: "Are handbags and backpacks allowed into the venue?",
    points: [
      "Regular-sized backpacks or small single-compartment bags - like sling bags, handbags, tote bags, etc. are allowed. Avoid carrying large backpacks with multiple compartments. Large bags will be subjected to baggage scanning, and smaller bags will be checked by hand.",
    ],
  },
  {
   
    title: "What's allowed & not allowed to be carried to the venue?",
    points: [
      `Here's <a style= "color: black  " href="https://drive.google.com/file/d/1rYs4hhaNHn70pXG5j-NG73MC5o5qpYIi/view" target="_blank">a list of things</a> that are permitted or not at the event.`
    ],
  },
  {
    title: "Contact Us",
    points: [
      `Follow <a style= "color: black  " href="https://www.bandland.in/" target="_blank">www.bandland.in </a>/ <a style= "color: black  " href="https://www.bandland.in/" target="_blank" >@bandland.in</a> on socials for updates!`,
      `<a style= "color: black  " href="mailto:bandland.help@bookmyshow.com" target="_blank" >Email us here</a> for all general queries.`,
    ],
  },
];
const MERCHANDISE = [
  {
    title: "Can I buy the Bandland official merchandise online?",
    points: [
      'Yes, you can purchase the official Bandland merchandise online  <a href="https://www.redwolf.in/bandland-merchandise" target="_blank">here</a> . All orders placed before November 14, 2024, will be eligible for home delivery. After this cut-off date, all orders placed online will be available for on-ground pickup only.',
    ],
  },
  {
    title: "How many days will it take to deliver?",
    points: [
      "Delivery will take approximately 7 working days.",
    ],
  },
  {
    title: "If the size doesn't fit, can I exchange it on the ground?",
    points: [
      "We’ve aligned our size charts with renowned brands to ensure fit standards. For now, we have a no-exchange, no-return policy.",
    ],
  },
  {
    title: "Is any artist bringing their own merchandise?",
    points: [
      "Besides the official Bandland merchandise, Everything Everything, Bloodywood, and Dot. will bring their own merchandise on-ground.",
    ],
  },
  {
    title: "What are the merchandise return policies?",
    points: [
      "Currently, we do not have any return policy. We’ll update this space if that changes!",
    ],
  },
  {
    title: "I have a query, who can I contact?",
    points: [
      `For any queries, please contact Red Wolf by writing to <a style="color: black" href="mailto:orders@redwolf.in" target="_blank">orders@redwolf.in</a>.`,
    ],
  },
];







const FAQ = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState(false);
  const isSMallestScreen = useMediaQuery("(max-width:900px)");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

useEffect(()=>{
  window.scrollTo(0, 0);
},[])

  const pageContent = (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      flexDirection="column"
      width="100%"
      className=" flex-center flex-col align-center text-center ticketTop "
    >
    
      <SideCurtains
        leftImageSrc="https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/remaining/bushesleft/faqs.webp"
        rightImageSrc="https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/remaining/bushesright/faqs.webp"
        mobileleft={
          "https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/remaining/lefttickets.webp"
        }
        mobileright={
          "https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/remaining/righttickets.webp"
        }
      />
      <Box width={"100%"}>
      <StyledTypography
        fontFamily="modesto-condensed"
        lineHeight={1.2}
        sx={{
          paddingTop: {
            xs: `${67}px`,
            sm: `${76}px`,
            md: `${85}px`,
            lg: `${125}px`,
            xl: `${125}px`,
          },
          paddingBottom: {
            xs: "10px",
            sm: "16px",
            md: "30px",
            lg: "32px",
            xl: "32px",
          },
          fontSize: {
            xs: "30px",
            sm: "30px",
            md: "40px",
            lg: "56px",
            xl: "56px",
            xll: "65px",
          },
        }}
      >
        FREQUENTLY ASKED QUESTIONS
      </StyledTypography>
        <Box sx={{overflowY:"auto"}} maxHeight={  isSMallestScreen? "1650px": ""} >
        <GeneralInfoSection 
        title="GENERAL INFO" faqs={Generalquery} />
        <GeneralInfoSection
          title="TICKET INFO"
          faqs={ticketInformation}
          marginTop={{
            xs: "21px",
            sm: "35px",
            md: "50px",
            lg: "72px",
            xl: "72px",
          }}
        />
        <GeneralInfoSection
          title="INCLUSIONS & AMENITIES"
          faqs={INCLUSION}
          marginTop={{
            xs: "21px",
            sm: "35px",
            md: "50px",
            lg: "81px",
            xl: "82px",
          }}
        />
        <GeneralInfoSection
          title="DO's & DON'Ts"
          faqs={doAndDont}
          marginTop={{
            xs: "21px",
            sm: "35px",
            md: "50px",
            lg: "72px",
            xl: "72px",
          }}
        />
         <GeneralInfoSection
          title="OFFICIAL MERCHANDISE"
          faqs={MERCHANDISE}
          marginTop={{
            xs: "21px",
            sm: "35px",
            md: "50px",
            lg: "72px",
            xl: "72px",
          }}
        />
        </Box>
      </Box>

      <Sponsors
        svgSrc={
          "https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/finalChanges/faqSponsors.png"
        }
        mt={20} mb={5}
      />

      <Footer />
    </Box>
  );
  return (
    <Box>
      <Helmet>
        <title>Bandland 2024 Frequently Asked Questions</title>
        <link rel="canonical" href="https://www.bandland.in/#/faq" />
        <meta
      property="og:title"
      content="Bandland 2024 Frequently Asked Questions"
    />
    <meta
      property="og:description"
      content="Checkout the customer queries for Bandland and get general information on artist line-up, ticket booking, VIP & platinum packages and more through the FAQ section"
    /> 
     <meta
      name="title"
      content="Bandland 2024 Frequently Asked Questions"
    />
        <meta
          name="description"
          content="Checkout the customer queries for Bandland and get general information on artist line-up, ticket booking, VIP & platinum packages and more through the FAQ section"
        />
      
<script data-react-helmet="true" type="application/ld+json">
{
  JSON.stringify({
    "@context": "http://schema.org",
    "@type": "Organization",
    "url": "https://www.bandland.in/",
    "logo": "https://in.bmscdn.com/bmsin/new/BMS_logo_new.png",
    "name": "Bandland",
    "sameAs": [

                 
      "https://www.facebook.com/Bandland.in/",
      "https://twitter.com/BandlandIndia/",
      "https://www.youtube.com/@BandlandIndia ",
     "https://www.instagram.com/bandland.in/"
    ],
    "contactPoint": [{
      "@type": "ContactPoint",
      "emaildId": "CustomerExperienceForce@bookmyshow.com",
      "contactType": "customer service",
      "areaServed": "IN",
      "availableLanguage": ["English", "Hindi", "Marathi", "Tamil", "Telugu", "Kannada", "Gujarati", "Punjabi"]
    }]
  })
}
</script>

<script data-react-helmet="true" type="application/ld+json">
{
  JSON.stringify({
    "@context": "http://schema.org",
    "@type": "WebSite",
    "name": "Bandland",
    "url": "https://www.bandland.in/"
  })
}
</script>

<script type="application/ld+json">
{
  JSON.stringify({
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": "https://example.com/dresses",
          "name": "Dresses"
        }
      },
      {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@id": "https://example.com/dresses/real",
          "name": "Real Dresses"
        }
      }
    ]
  })
}
</script>

{/* <script type="application/ld+json">
{
  JSON.stringify({
    "@context": "http://schema.org/",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "QUESTION 01?",
        "author": {
          "@type": "Organization",
          "name": "Bandland"
        },
        "dateCreated": "2019-XX-XXT14:54:00-05:00",
        "answerCount": "1",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "COPY THE ANSWER HERE",
          "dateCreated": "2019-XX-XXT14:54:00-05:00",
          "author": {
            "@type": "Organization",
            "name": "Bandland"
          }
        }
      },
      {
        "@type": "Question",
        "name": "QUESTION 02?",
        "author": {
          "@type": "Organization",
          "name": "Bandland"
        },
        "dateCreated": "2017-XX-XXT16:58:00-05:00",
        "answerCount": "1",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "COPY THE ANSWER HERE",
          "dateCreated": "2017-XX-XXT10:08:00-05:00",
          "author": {
            "@type": "Organization",
            "name": "Bandland"
          }
        }
      },
      {
        "@type": "Question",
        "name": "QUESTION 03?",
        "author": {
          "@type": "Organization",
          "name": "Bandland"
        },
        "dateCreated": "2019-XX-XXT14:00:00-05:00",
        "answerCount": "1",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "COPY THE ANSWER HERE",
          "dateCreated": "2019-XX-XXT14:00:00-05:00",
          "author": {
            "@type": "Organization",
            "name": "Bandland"
          }
        }
      },
      {
        "@type": "Question",
        "name": "QUESTION 04?",
        "author": {
          "@type": "Organization",
          "name": "Bandland"
        },
        "dateCreated": "2019-XX-XXT14:17:00-05:00",
        "answerCount": "1",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "COPY THE ANSWER HERE",
          "dateCreated": "2019-XX-XXT14:17:00-05:00",
          "author": {
            "@type": "Organization",
            "name": "Bandland"
          }
        }
      },
      {
        "@type": "Question",
        "name": "QUESTION 05?",
        "author": {
          "@type": "Organization",
          "name": "Bandland"
        },
        "dateCreated": "2019-XX-XXT19:30:00-05:00",
        "answerCount": "1",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "COPY THE ANSWER HERE",
          "dateCreated": "2019-XX-XXT19:30:00-05:00",
          "author": {
            "@type": "Organization",
            "name": "Bandland"
          }
        }
      }
    ]
  })
}
</script> */}
<script type="application/ld+json">
{
  JSON.stringify({
    "@context": "http://schema.org/",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "When is the event taking place?",
        "author": {
          "@type": "Organization",
          "name": "Bandland"
        },
        "dateCreated": "2024-08-07T14:54:00-05:00",
        "answerCount": "1",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Bandland will take place on November 23rd and 24th at Nice Grounds, BIEC, Bengaluru.",
          "dateCreated": "2024-08-07T14:54:00-05:00",
          "author": {
            "@type": "Organization",
            "name": "Bandland"
          }
        }
      },
      {
        "@type": "Question",
        "name": "What is the duration of the festival?",
        "author": {
          "@type": "Organization",
          "name": "Bandland"
        },
        "dateCreated": "2024-08-07T14:54:00-05:00",
        "answerCount": "1",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "The festival is a two-day festival, with 8-9 hours of performances per day.",
          "dateCreated": "2024-08-07T14:54:00-05:00",
          "author": {
            "@type": "Organization",
            "name": "Bandland"
          }
        }
      },
      {
        "@type": "Question",
        "name": "How do I get to the venue?",
        "author": {
          "@type": "Organization",
          "name": "Bandland"
        },
        "dateCreated": "2024-08-07T14:54:00-05:00",
        "answerCount": "1",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Bangalore Metro - The venue BIEC is just 2 kms away from the last stop, Nagasandra, of the phase two (Green Line) of Bangalore Metro. Kempegowda International Airport is 40 km away from the venue. If you are staying in the heart of the city, then you have the option of taking the airport shuttle buses. Nearest Bus Stop - Madavara. Shuttle Buses - Information coming soon. Link here to see google maps link: https://maps.app.goo.gl/7FyB8vBVUs7wrWPy6",
          "dateCreated": "2024-08-07T14:54:00-05:00",
          "author": {
            "@type": "Organization",
            "name": "Bandland"
          }
        }
      },
      {
        "@type": "Question",
        "name": "Can I drive to the event?",
        "author": {
          "@type": "Organization",
          "name": "Bandland"
        },
        "dateCreated": "2024-08-07T14:54:00-05:00",
        "answerCount": "1",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, the venue is accessible by road. For patrons above legal drinking age, please have a designated driver in case you choose to come with your personal vehicles.",
          "dateCreated": "2024-08-07T14:54:00-05:00",
          "author": {
            "@type": "Organization",
            "name": "Bandland"
          }
        }
      },
      {
        "@type": "Question",
        "name": "Is there parking available at the venue?",
        "author": {
          "@type": "Organization",
          "name": "Bandland"
        },
        "dateCreated": "2024-08-07T14:54:00-05:00",
        "answerCount": "1",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "There is limited parking available for The Citizens on a first come first serve basis (Parking Fee - Applicable). Perks & privileges on parking are mentioned in the special category ticket descriptions.",
          "dateCreated": "2024-08-07T14:54:00-05:00",
          "author": {
            "@type": "Organization",
            "name": "Bandland"
          }
        }
      },
      {
        "@type": "Question",
        "name": "How will I get my tickets?",
        "author": {
          "@type": "Organization",
          "name": "Bandland"
        },
        "dateCreated": "2024-08-07T14:54:00-05:00",
        "answerCount": "1",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Tickets will be home delivered at a date closer to the event - updates for the same will be shared across our social media channels. You can always refer to your digital ticket copy in your Bandland transaction history.",
          "dateCreated": "2024-08-07T14:54:00-05:00",
          "author": {
            "@type": "Organization",
            "name": "Bandland"
          }
        }
      },
      {
        "@type": "Question",
        "name": "Is there a ticket limit?",
        "author": {
          "@type": "Organization",
          "name": "Bandland"
        },
        "dateCreated": "2024-08-07T14:54:00-05:00",
        "answerCount": "1",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "You can buy up to 10 tickets per person. All ticket categories are subject to availability - so get yours before they sell out!",
          "dateCreated": "2024-08-07T14:54:00-05:00",
          "author": {
            "@type": "Organization",
            "name": "Bandland"
          }
        }
      },
      {
        "@type": "Question",
        "name": "Will the tickets be available for sale on any other platform?",
        "author": {
          "@type": "Organization",
          "name": "Bandland"
        },
        "dateCreated": "2024-08-07T14:54:00-05:00",
        "answerCount": "1",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "No, the official tickets will only be available for sale on Bandland. We strongly recommend you not to purchase them via any third party to avoid the possibility of counterfeit tickets.",
          "dateCreated": "2024-08-07T14:54:00-05:00",
          "author": {
            "@type": "Organization",
            "name": "Bandland"
          }
        }
      },
      {
        "@type": "Question",
        "name": "How can I purchase tickets as an international customer?",
        "author": {
          "@type": "Organization",
          "name": "Bandland"
        },
        "dateCreated": "2024-08-07T14:54:00-05:00",
        "answerCount": "1",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Use the pin/zip code 123456 to digitally make your purchase outside of India. Collect your wristbands at the Box Office on Nov 23rd & 24th 2024. For more queries, write here!",
          "dateCreated": "2024-08-07T14:54:00-05:00",
          "author": {
            "@type": "Organization",
            "name": "Bandland"
          }
        }
      },
      {
        "@type": "Question",
        "name": "How do I get a refund if I can't make it?",
        "author": {
          "@type": "Organization",
          "name": "Bandland"
        },
        "dateCreated": "2024-08-07T14:54:00-05:00",
        "answerCount": "1",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Tickets are not transferable or cancellable. In case of any queries, write to us.",
          "dateCreated": "2024-08-07T14:54:00-05:00",
          "author": {
            "@type": "Organization",
            "name": "Bandland"
          }
        }
      },
      {
        "@type": "Question",
        "name": "What happens if the event is canceled?",
        "author": {
          "@type": "Organization",
          "name": "Bandland"
        },
        "dateCreated": "2024-08-07T14:54:00-05:00",
        "answerCount": "1",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "You'll be notified in advance via email & phone, with an option to claim a refund or carry it forward for a future date (if rescheduled). Lineups are subject to change in the event of rescheduling.",
          "dateCreated": "2024-08-07T14:54:00-05:00",
          "author": {
            "@type": "Organization",
            "name": "Bandland"
          }
        }
      },
      {
        "@type": "Question",
        "name": "Are children allowed for the show?",
        "author": {
          "@type": "Organization",
          "name": "Bandland"
        },
        "dateCreated": "2024-08-07T14:54:00-05:00",
        "answerCount": "1",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Kids are absolutely welcome! They need to be accompanied by a guardian, who must hold a ticket on their behalf. Age eligibility details are shared below.",
          "dateCreated": "2024-08-07T14:54:00-05:00",
          "author": {
            "@type": "Organization",
            "name": "Bandland"
          }
        }
      },
      {
        "@type": "Question",
        "name": "What children's ages are eligible for a ticket?",
        "author": {
          "@type": "Organization",
          "name": "Bandland"
        },
        "dateCreated": "2024-08-07T14:54:00-05:00",
        "answerCount": "1",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Kids below the ages of 5 are allowed to enter the festival for free. Ages 5-17 will need to hold tickets that are purchased and accompanied by their guardian. They must hold the same ticket category as their guardian.",
          "dateCreated": "2024-08-07T14:54:00-05:00",
          "author": {
            "@type": "Organization",
            "name": "Bandland"
          }
        }
      },
      {
        "@type": "Question",
        "name": "Will there be F&B available at the venue?",
        "author": {
          "@type": "Organization",
          "name": "Bandland"
        },
        "dateCreated": "2024-08-07T14:54:00-05:00",
        "answerCount": "1",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, food and beverages will both be available for purchase at the venue. Outside food or beverages will not be permitted.",
          "dateCreated": "2024-08-07T14:54:00-05:00",
          "author": {
            "@type": "Organization",
            "name": "Bandland"
          }
        }
      },
      {
        "@type": "Question",
        "name": "Is the venue air-conditioned?",
        "author": {
          "@type": "Organization",
          "name": "Bandland"
        },
        "dateCreated": "2024-08-07T14:54:00-05:00",
        "answerCount": "1",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "No, this is an open-air concert festival.",
          "dateCreated": "2024-08-07T14:54:00-05:00",
          "author": {
            "@type": "Organization",
            "name": "Bandland"
          }
        }
      },
      {
        "@type": "Question",
        "name": "Is this a seated show?",
        "author": {
          "@type": "Organization",
          "name": "Bandland"
        },
        "dateCreated": "2024-08-07T14:54:00-05:00",
        "answerCount": "1",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "No, the festival will be a walk-in standing concert experience. Any seating options will be specified and made available in special category tickets.",
          "dateCreated": "2024-08-07T14:54:00-05:00",
          "author": {
            "@type": "Organization",
            "name": "Bandland"
          }
        }
      },
      {
        "@type": "Question",
        "name": "Will I be able to switch my ticket to a different category?",
        "author": {
          "@type": "Organization",
          "name": "Bandland"
        },
        "dateCreated": "2024-08-07T14:54:00-05:00",
        "answerCount": "1",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "No, tickets once purchased will not be upgradable or changeable in terms of category.",
          "dateCreated": "2024-08-07T14:54:00-05:00",
          "author": {
            "@type": "Organization",
            "name": "Bandland"
          }
        }
      },
      {
        "@type": "Question",
        "name": "Is there an all-area-access ticket with an artist meet & greet?",
        "author": {
          "@type": "Organization",
          "name": "Bandland"
        },
        "dateCreated": "2024-08-07T14:54:00-05:00",
        "answerCount": "1",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "There are no all-area-access passes. Perks and privileges on special category tickets are mentioned in the ticket descriptions.",
          "dateCreated": "2024-08-07T14:54:00-05:00",
          "author": {
            "@type": "Organization",
            "name": "Bandland"
          }
        }
      },
      
      {
            "@type": "Question",
            "name": "What is included in a VIP / Platinum ticket?",
            "author": {
              "@type": "Organization",
              "name": "Bandland"
            },
            "dateCreated": "2014-11-23T14:54:00-05:00",
            "answerCount": "1",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Tuborg Rock & Roller:<br/>1. VIP admission to both days of the festival.<br/>2. Dedicated Tuborg Rock & Roller Zone with elevated deck which has sight of both stages.<br/>3. Exclusive F&B area and toilets in the VIP area. All F&B is purchasable.<br/>4. Official Bandland merch - Can avail free merchandise (on selected items only).<br/>5. Preferred parking near the venue on a first come first serve basis.<br/>6. Dedicated entry lane for fast access into the festival.<br/>7. Kids aged 0-5 accompanying VIP ticket holders can enter the festival for free.<br/>8. Kids aged 5+ and older (born before Dec 15, 2018) need to buy a VIP ticket.<br/><br/> Ace of Spades:<br/>1. Platinum admission to both days of the festival.<br/>2. Access to the Ace of Spades pits on both stages.<br/>3. Exclusive Ace of Spades lounge with seating.<br/>4. Front of both stages pit access.<br/>5. Complimentary snacks and drinks at the Ace of Spades Club.<br/>6. Exclusive bars and air-conditioned toilets.<br/>7. Official Bandland merch item + fast access to the merch tent at the festival.<br/>8. Free parking at the venue.<br/>9. Hand-delivered festival access kit.",
              "dateCreated": "2014-11-23T14:54:00-05:00",
              "author": {
                "@type": "Organization",
                "name": "Bandland"
              }
            }
          },
          {
            "@type": "Question",
            "name": "Do you accommodate people with special needs?",
            "author": {
              "@type": "Organization",
              "name": "Bandland"
            },
            "dateCreated": "2014-11-23T14:54:00-05:00",
            "answerCount": "1",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The festival is wheelchair-friendly and our crew will be happy to assist anyone with special needs. For more queries, write to us at CustomerExperienceForce@bandland.in. We encourage attendees to bring their own wheelchairs; one will be provided at the festival only in the case of any on-ground medical emergencies.",
              "dateCreated": "2014-11-23T14:54:00-05:00",
              "author": {
                "@type": "Organization",
                "name": "Bandland"
              }
            }
          },
          {
            "@type": "Question",
            "name": "Can I bring my camera?",
            "author": {
              "@type": "Organization",
              "name": "Bandland"
            },
            "dateCreated": "2014-11-23T14:54:00-05:00",
            "answerCount": "1",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Personal cameras and video recording devices will not be allowed inside the venue.",
              "dateCreated": "2014-11-23T14:54:00-05:00",
              "author": {
                "@type": "Organization",
                "name": "Bandland"
              }
            }
          },
          {
            "@type": "Question",
            "name": "Will there be medical assistance?",
            "author": {
              "@type": "Organization",
              "name": "Bandland"
            },
            "dateCreated": "2014-11-23T14:54:00-05:00",
            "answerCount": "1",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, there will be first aid and medical assistance available at the venue.",
              "dateCreated": "2014-11-23T14:54:00-05:00",
              "author": {
                "@type": "Organization",
                "name": "Bandland"
              }
            }
          },
          {
            "@type": "Question",
            "name": "Will there be any security arrangements?",
            "author": {
              "@type": "Organization",
              "name": "Bandland"
            },
            "dateCreated": "2014-11-23T14:54:00-05:00",
            "answerCount": "1",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, the safety of our patrons is our top priority. There will be security arrangements in place to ensure a safe and enjoyable experience for all attendees.",
              "dateCreated": "2014-11-23T14:54:00-05:00",
              "author": {
                "@type": "Organization",
                "name": "Bandland"
              }
            }
          },
          {
            "@type": "Question",
            "name": "Can I bring my pet?",
            "author": {
              "@type": "Organization",
              "name": "Bandland"
            },
            "dateCreated": "2014-11-23T14:54:00-05:00",
            "answerCount": "1",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "No, pets are not allowed at the festival.",
              "dateCreated": "2014-11-23T14:54:00-05:00",
              "author": {
                "@type": "Organization",
                "name": "Bandland"
              }
            }
          },
          {
            "@type": "Question",
            "name": "Is smoking allowed at the venue?",
            "author": {
              "@type": "Organization",
              "name": "Bandland"
            },
            "dateCreated": "2014-11-23T14:54:00-05:00",
            "answerCount": "1",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Smoking is allowed only in designated smoking zones at the venue.",
              "dateCreated": "2014-11-23T14:54:00-05:00",
              "author": {
                "@type": "Organization",
                "name": "Bandland"
              }
            }
          },
          {
            "@type": "Question",
            "name": "Are handbags and backpacks allowed into the venue?",
            "author": {
              "@type": "Organization",
              "name": "Bandland"
            },
            "dateCreated": "2014-11-23T14:54:00-05:00",
            "answerCount": "1",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Regular-sized backpacks or small single-compartment bags - like sling bags, handbags, tote bags, etc. are allowed. Avoid carrying large backpacks with multiple compartments. Large bags will be subjected to baggage scanning, and smaller bags will be checked by hand.",
              "dateCreated": "2014-11-23T14:54:00-05:00",
              "author": {
                "@type": "Organization",
                "name": "Bandland"
              }
            }
          },
          {
            "@type": "Question",
            "name": "What's allowed & not allowed to be carried to the venue?",
            "author": {
              "@type": "Organization",
              "name": "Bandland"
            },
            "dateCreated": "2014-11-23T14:54:00-05:00",
            "answerCount": "1",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Here's a list of things that are permitted or not at the event: https://drive.google.com/file/d/1rYs4hhaNHn70pXG5j-NG73MC5o5qpYIi/view",
              "dateCreated": "2014-11-23T14:54:00-05:00",
              "author": {
                "@type": "Organization",
                "name": "Bandland"
              }
            }
          },
          {
            "@type": "Question",
            "name": "Contact Us",
            "author": {
              "@type": "Organization",
              "name": "Bandland"
            },
            "dateCreated": "2014-11-23T14:54:00-05:00",
            "answerCount": "1",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Follow www.bandland.in / @bandland.in on socials for updates! Email us at CustomerExperienceForce@bookmyshow.com for all general queries.",
              "dateCreated": "2014-11-23T14:54:00-05:00",
              "author": {
                "@type": "Organization",
                "name": "Bandland"
              }
            }
          }
        ]
      })
  }
        </script>


        {/* <meta name="keywords" content="Bandland, Bandland 2024, Bandland concert, Bandland party, Bandland Bangalore, Bandland tickets, Bandland 2024, Bandland Bangalore tickets" /> */}
      </Helmet>
      <Header pageContent={pageContent} />
    </Box>
  );
};

export default FAQ;
