import React, { useEffect, useState } from "react";
// import Stars from "../../images/Home/Stars.png";
// import Foot from "../../images/Home/Foot.png";
import { Bolt } from "@mui/icons-material";
// import WhiteLogo from "../../images/Bandland Logo White.svg";
// import BMSLiveSvg from "../../images/BMS LIVE.svg";
// import "../../styles/Home.css";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
// import FooterBase from "../../images/Home/Footer.png";
// import MobileFooterBase from "../../images/Home/SmallFooter.png";
import "./footer.css";
import { Link as RouteLink } from "react-router-dom";
import { Link } from "@mui/material";
import theme from "../../theme";
import IconButton from "@mui/material/IconButton";
import { Close, Facebook, Instagram, YouTube } from "@mui/icons-material";

import XIcon from "@mui/icons-material/X";
import useInView from "../../hooks/Lazyloader";

const Footer = () => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const [ref, isInView] = useInView({
    threshold: 0.1, // Adjust this value as needed
    triggerOnce: true, // This ensures the card renders only once when it comes into view
  });
  const [shouldRender, setShouldRender] = useState(false);
  useEffect(() => {
    if (isInView) {
      setShouldRender(true);
    }
  }, [isInView]);

  // Function to update the screen height
  const updateScreenHeight = () => {
    setScreenHeight(window.innerHeight);
    console.log("window:", window.innerHeight);
  };

  // Calculate the screen height initially and on window resize
  useEffect(() => {
    updateScreenHeight();
    window.addEventListener("resize", updateScreenHeight);
    return () => {
      window.removeEventListener("resize", updateScreenHeight);
    };
  }, []);
  if (!shouldRender) {
    return <div ref={ref} style={{ height: "280px", width: "100%" }} />; // Placeholder
  }
  return (
    <Box
      sx={{
        boxSizing: "border-box",
        position: "relative",
        width: "100%",
        minHeight: {
          xs: "300px",
          sm: "350px",
          md: "400px",
          lg: "450px",
          xl: "500px",
        },
        height: "auto",
        backgroundColor: "#F08A53",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
       
      }}
    >
      {/* <img
        className="pos-absolute bottom-0 z-index-1"
        src={Foot}
        style={{ width: "90%" }}
        alt="img"
      /> */}
      {/* {isSmallScreen ? <img
        className="pos-absolute bottom-2 z-index-1"
        
        src="https://bandland.s3.ap-south-1.amazonaws.com/images/Home/Stars.png"
        style={{ width: "100%", position: 'absolute', bottom: 2, zIndex: 1 }}
        alt="img"
        loading="lazy"
      /> : <></>} */}
      <Box
        sx={{
          position: "relative",
          zIndex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Box
          zIndex="4"
          display="flex"
          flexDirection="column"
          alignItems="center"
          width={"100%"}

          // sx={{ top: { xs: "30%", sm: "30%", md: "30%", lg: "30%", xl: "30%" } }}
        >
          <img
            src={
              isSmallScreen
                ? "https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/remaining/common/footerlogo.webp"
                : "https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/mobile/common/footerLogo.webp"
            }
            className="whiteLogoImg"
            alt="img"
          />

          <Box
            sx={{
              paddingBottom: {
                xs: "23px",
                sm: "28px",
                md: "30px",
                lg: "40px",
                xl: "45px",
              },
              paddingTop: {
                xs: "10px",
                sm: "12px",
                md: "20px",
                lg: "31px",
                xl: "31px",
              },
              display: "flex",
              justifyContent: "center",
              gap: { xs: "10", sm: "12", md: "13px", lg: "13px", xl: "13px" },
            }}
          >
            <IconButton>
              <Instagram
                sx={{
                  color: "black",
                  fontSize: {
                    xs: "33px",
                    sm: "40px",
                    md: "50px",
                    lg: "50px",
                    xl: "50px",
                  },
                }}
                onClick={() =>
                  window.open("https://www.instagram.com/bandland.in/")
                }
              />
            </IconButton>
            <IconButton>
              <XIcon
                sx={{
                  backgroundColor: "black",
                  borderRadius: "5px",
                  color: "#F08A53",
                  fontSize: {
                    xs: "28px",
                    sm: "32px",
                    md: "36px",
                    lg: "40px",
                    xl: "40px",
                  },
                }}
                onClick={() => window.open("https://twitter.com/BandlandIndia")}
              />
            </IconButton>
            <IconButton paddingRight={"0px"}>
              <Box
                padding={{ xs: "0.8px", sm: "3px", md: "6px", lg: "6px" }}
                paddingX={{ xs: "6px", md: "6px", lg: "7px" }}
                paddingBottom={{ xs: "0px", md: "0px", lg: "0px" }}
                onClick={() => window.open("https://www.youtube.com/@BandlandIndia")}
                borderRadius={"5px"}
                backgroundColor={"black"}
              >
                <YouTube
                  sx={{
                    color: "#F08A53",
                    fontSize: { xs: "medium", md: "large", lg: "larger" },
                  }}
                />
              </Box>
            </IconButton>
            <IconButton paddingX={"0px"} style={{marginLeft:"-3%"}}  >
              <Facebook
                sx={{
                  color: "black",
                  fontSize: {
                    xs: "36px",
                    sm: "44px",
                    md: "54px",
                    lg: "54px",
                    xl: "54px",
                  },
                }}
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/Bandland.in/"
                  )
                }
              />
            </IconButton>
          </Box>



          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: {
                  xs: "26px",
                  sm: "28px",
                  md: "32px",
                  lg: "40px",
                  xl: "42px",
                },
                marginBottom: "20px",
                paddingBottom: {
                  xs: "25px",
                  sm: "25px",
                  md: "30px",
                  lg: "35px",
                  xl: "40px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "modesto-condensed",
                    fontSize: {
                      xs: "18px",
                      sm: "24px",
                      md: "36px",
                      lg: "48px",
                      xl: "50px",
                    },
                  }}
                >
                  FESTIVAL
                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    flexDirection: { xs: "row", sm: "row" },
                    gap: { xs: "0px", sm: "0px", lg: "0px", xl: "0px" },
                  }}
                >
                  <Button
                    paddingY={"0px"}
                    component={RouteLink}
                    to="/"
                    sx={{
                      minWidth: "auto",
                      fontSize: {
                        xs: "10px",
                        sm: "14px",
                        md: "17px",
                        lg: "22px",
                        xl: "22px",
                      },
                      fontFamily: "Texturina",
                      color: "black",
                      textTransform: "none",
                      paddingRight: {
                        xs: "0px",
                        sm: "0px",
                        md: "2px",
                        lg: "3px",
                      },
                      paddingY: "0px",
                    }}
                  >
                    Home
                  </Button>

                  <Button
                    component={RouteLink}
                    to="/tickets"
                    sx={{
                      fontSize: {
                        xs: "10px",
                        sm: "14px",
                        md: "17px",
                        lg: "22px",
                        xl: "22px",
                      },
                      fontFamily: "Texturina",
                      color: "black",
                      textTransform: "none",
                      padding: 0,
                    }}
                  >
                    | Book Now |
                  </Button>

                  <Button
                    component={RouteLink}
                    to="/contact-us"
                    sx={{
                      fontSize: {
                        xs: "10px",
                        sm: "14px",
                        md: "17px",
                        lg: "22px",
                        xl: "22px",
                      },
                      fontFamily: "Texturina",
                      color: "black",
                      textTransform: "none",
                      padding: "0px",
                      paddingLeft: {
                        xs: "0px",
                        sm: "0px",
                        md: "2px",
                        lg: "3px",
                      },
                    }}
                  >
                    Stay Updated
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    fontSize: {
                      xs: "18px",
                      sm: "24px",
                      md: "36px",
                      lg: "48px",
                      xl: "50px",
                    },
                    fontFamily: "modesto-condensed",
                  }}
                >
                  HELP
                </Typography>
                <Button
                  component="a"
                  href=" http://bandland.help@bookmyshow.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    fontSize: {
                      xs: "10px",
                      sm: "14px",
                      md: "17px",
                      lg: "22px",
                      xl: "22px",
                    },

                    fontFamily: "Texturina",
                    color: "black",
                    textTransform: "none",
                    padding: {
                      xs: "0px",
                      sm: "0px",
                      md: "0px",
                      lg: "0px",
                      xl: "0px",
                    },
                  }}
                >
                  Contact Us
                </Button>
              </Box>
            </Box>
            <Box></Box>
          </Box>

          <img
            src="https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/finalChanges/producedBy.png"
            className="live-logo footerBGImg"
            alt="img"
          />

          <Box
            sx={{
              // mt: { xs: 0, sm: 2 },
              paddingBottom: {
                xs: "25px",
                sm: "25px",
                md: "30px",
                lg: "35px",
                xl: "40px",
              },
              paddingTop: {
                xs: "13px",
                sm: "15px",
                md: "26px",
                lg: "36px",
                xl: "36px",
              },
            }}
          >
            <Typography
              variant="caption"
              fontFamily="texturina"
              sx={{
                fontSize: {
                  xs: "12px",
                  sm: "12px",
                  md: "16px",
                  lg: "18px",
                  xl: "20px",
                  xll: "22px",
                },
               
              }}
            >
              ©{" "}
              <a
                href="https://drive.google.com/file/d/1sh4XL3gvfAlXmq5agY8BjQ4pOG8YUTef/view"
                className="privacyTag"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy
              </a>{" "}
              |{" "}
              <a
                href="https://drive.google.com/file/d/1gIPDMCcqxn7fZI2YxdpHf8DZx6Zqrmgc/view"
                target="_blank"
                rel="noopener noreferrer"
                className="privacyTag"
              >
                Terms & Conditions
              </a>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
