import {
  Box,
  Button,
  CircularProgress,
  Container,
  Drawer,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

// import HeaderBase from "../../images/Header Base.png";
// import TestBanner from "https://bandland.s3.ap-south-1.amazonaws.com/images/FAQ+banner+1.svg";
// import TestBanner from "../../images/FAQ banner 1.svg";
// import TestBannerMD from "https://bandland.s3.ap-south-1.amazonaws.com/images/Banners/FAQ+banner+1+3400px+height.png";
// import TestBannerMD from "../../images/Banners/FAQ banner 1 3400px height.png";
// import TestBannerLG from "https://bandland.s3.ap-south-1.amazonaws.com/images/Banners/FAQ+banner+1+2800px+height.png";
// import TestBannerLG from "../../images/Banners/FAQ banner 1 2800px height.png";
// import TestBannerXL from "../../images/Banners/FAQ banner 1 2400px height.png";
// import TestBannerXL from "https://bandland.s3.ap-south-1.amazonaws.com/images/Banners/FAQ+banner+1+2400px+height.png";
// import TestBannerXLL from "../../images/Banners/FAQ banner 1 2000px height.png";
// import TestBannerXLL from "https://bandland.s3.ap-south-1.amazonaws.com/images/Banners/FAQ+banner+1+2000px+height.png";
// import TestBannerXLLL from "../../images/Banners/FAQ banner 1 1800px height.png";
// import TextBanner from "../../images/Home/Text Banner.png";
// import OrangeBand from "../../images/Home/orange-band.png";
// import BannerLine from "../../images/Line2.svg";
// import SideBorder from "https://bandland.s3.ap-south-1.amazonaws.com/images/Home/SideLeaves.svg";
// import SideBorder from "../../images/Home/SideLeaves.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Bolt, Margin } from "@mui/icons-material";
// import Banner from "../../images/testBanner.svg";
import "../Hamburger/index.css";
// import Footer from "../../common/Footer/index"
import "./index.css";
// import Loader from "../../images/loader.gif"

import Hamburger from "../Hamburger";
import { useLocation, Link } from "react-router-dom";
import ReactGA from "react-ga";
import { useNavigate } from "react-router-dom";
// import FinalBanner1 from "../../assets/banner1.webp";

const Header = ({ gogoDollBanner, pageContent }) => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const theme = useTheme();
  // console.log('theme:', theme.globalStyles.headerImage)
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.up("sm"));
  // console.log('isSmallScreen:', isSmallScreen)
  const isScreenXtraSmall = useMediaQuery(theme.breakpoints.up("xs"));
  // console.log('isScreenXtraSmall:', isScreenXtraSmall)
  const isScreenLg = useMediaQuery(theme.breakpoints.up("lg"));
  const isScreenXL = useMediaQuery(theme.breakpoints.up("xl"));
  const isScreenXLL = useMediaQuery(theme.breakpoints.up("xll"));
  const isScreenXXL = useMediaQuery(theme.breakpoints.up("xxl"));
  // console.log('isScreenLg:', isMediumScreen,isScreenLg, isScreenXL, isScreenXLL)

  // const lineMargin = isScreenLg ? "-70%":"-90%"
  const TestBanner = isSmallScreen
    ? "https://bandland.s3.ap-south-1.amazonaws.com/images/FAQ+banner+1.svg"
    : "https://bandland.s3.ap-south-1.amazonaws.com/mobile/FAQ+banner+1+(1).webp";
  const TestBannerMD = isSmallScreen
    ? "https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/Home/BadLandLogo.webp"
    : "https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/Home/BadLandLogo.webp";
  const TestBannerLG =
    "https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/Home/BadLandLogo.webp";
  const TestBannerXL =
    "https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/Home/BadLandLogo.webp";
  const TestBannerXLL =
    "https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/Home/BadLandLogo.webp";

  const faqBannerNew =
    "https://bandland.s3.ap-south-1.amazonaws.com/artists/FAQ+banner+1+3400px+height_new.svg";

  const { pathname } = useLocation();
  const [backgroudBanner, setBackgroudBanner] = useState();

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // Function to update the screen height
  const updateScreenHeight = () => {
    setScreenHeight(window.innerHeight);
    setScreenWidth(window.innerWidth);
    console.log("window-height:", window.innerHeight);
    console.log("window-width:", window.innerWidth);
  };

  // Calculate the screen height initially and on window resize
  useEffect(() => {
    updateScreenHeight();
    window.addEventListener("resize", updateScreenHeight);
    return () => {
      window.removeEventListener("resize", updateScreenHeight);
    };
  }, []);

  useEffect(() => {
    switch (pathname) {
      case "/":
      // if (isScreenXXL) {
      //   console.log("Test Banner isScreenXLL:");
      //   if (screenHeight <= 1000) {
      //     setBackgroudBanner(FinalBanner2);
      //     console.log("test1");
      //   } else {
      //     setBackgroudBanner(FinalBanner2);
      //     console.log("test2");
      //   }
      // } else if (isScreenXL) {
      //   console.log("Test Banner isScreenXL:");
      //   if (screenHeight <= 800) {
      //     setBackgroudBanner(FinalBanner2);
      //     console.log("test3");
      //   } else {
      //     setBackgroudBanner(FinalBanner2);
      //     console.log("test4");
      //   }
      // } else if (isScreenLg) {
      //   console.log("Test Banner isScreenLg:");
      //   if (screenHeight <= 880) {
      //     setBackgroudBanner(FinalBanner2);
      //     console.log("test5");
      //   } else {
      //     setBackgroudBanner(FinalBanner3);
      //     console.log("test6");
      //   }
      // } else if (isMediumScreen) {
      //   if (screenHeight <= 1100) {
      //     setBackgroudBanner(FinalBanner3);
      //     console.log("test7");
      //   } else {
      //     setBackgroudBanner(FinalBanner3);
      //     console.log("test8");
      //   }
      //   console.log("Test Banner isMediumScreen:");
      // } else {
      //   // for tablet
      //   if (screenWidth >= 768) {
      //     setBackgroudBanner(FinalBanner3);
      //     console.log("test9");
      //   } else if (screenWidth >= 600) {
      //     setBackgroudBanner(FinalBanner3);
      //     console.log("test10");
      //   } else {
      //     if(screenWidth>=350){
      //       setBackgroudBanner(MobileBanner);
      //     }else{

      //       setBackgroudBanner(MobileBanner1);
      //       console.log("test11");
      //     }
      //   }
      //   // setBackgroudBanner(TestBanner)
      //   console.log("Test Banner Mobile:");
      // }
      // if (isScreenXXL) {
      //   console.log('Test Banner isScreenXLL:')
      //   if (screenHeight <= 1000) {
      //     setBackgroudBanner(TestBannerLG)
      //   }
      //   else {
      //     setBackgroudBanner(TestBannerLG)
      //   }
      // }
      // else if (isScreenXL) {
      //   console.log('Test Banner isScreenXL:')
      //   if (screenHeight <= 800) {
      //     setBackgroudBanner(TestBannerLG)
      //   }
      //   else {
      //     setBackgroudBanner(TestBannerMD)
      //   }
      // }
      // else if (isScreenLg) {
      //   console.log('Test Banner isScreenLg:')
      //   if (screenHeight <= 880) {
      //     setBackgroudBanner(TestBannerLG)
      //   }
      //   else {
      //     setBackgroudBanner(TestBannerMD)
      //   }
      // }
      // else if (isMediumScreen) {
      //   if (screenHeight <= 1100) {
      //     setBackgroudBanner(TestBannerLG)
      //   }
      //   else {
      //     setBackgroudBanner(TestBannerMD)
      //   }
      //   console.log('Test Banner isMediumScreen:')
      // }
      // else {
      //   // for tablet
      //   if (screenWidth >= 768) {
      //     setBackgroudBanner(TestBannerMD)
      //   }
      //   else {

      //     setBackgroudBanner(TestBanner)
      //   }
      //   // setBackgroudBanner(TestBanner)
      //   console.log('Test Banner Mobile:')
      // }
      // break;
      case "/stayupdated":
        if (isScreenXLL) {
          console.log("Test Banner isScreenXLL:");
          setBackgroudBanner(TestBannerXLL);
        } else if (isScreenXL) {
          console.log("Test Banner isScreenXL:");
          setBackgroudBanner(TestBannerXLL);
        } else if (isScreenLg) {
          console.log("Test Banner isScreenLg:");
          setBackgroudBanner(TestBannerXLL);
        } else if (isMediumScreen) {
          setBackgroudBanner(TestBannerXL);
          console.log("Test Banner isMediumScreen:");
        } else {
          // for tablet
          if (screenWidth >= 768) {
            setBackgroudBanner(TestBannerXL);
          } else {
            setBackgroudBanner(
              screenHeight <= 700 ? "FinalBanner4" : TestBannerMD
            );
          }
          // setBackgroudBanner(TestBannerMD)
          console.log("Test Banner Mobile:");
        }
        break;
      case "/about":
        if (isScreenXLL) {
          console.log("Test Banner isScreenXLL:");
          setBackgroudBanner(TestBannerXLL);
        } else if (isScreenXL) {
          console.log("Test Banner isScreenXL:");
          setBackgroudBanner(TestBannerXLL);
        } else if (isScreenLg) {
          console.log("Test Banner isScreenLg:");
          setBackgroudBanner(TestBannerXL);
        } else if (isMediumScreen) {
          setBackgroudBanner(TestBannerXL);
          console.log("Test Banner isMediumScreen:");
        } else {
          // for tablet
          if (screenWidth >= 768) {
            setBackgroudBanner("FinalBanner4");
          } else if (screenWidth >= 500) {
            setBackgroudBanner(
              screenHeight <= 450 ? TestBannerMD : TestBannerMD
            );
          } else {
            setBackgroudBanner(
              screenHeight <= 450 ? TestBannerMD : "FinalBanner3"
            );
          }
          // setBackgroudBanner(TestBannerMD)
          console.log("Test Banner Mobile:");
        }
        break;
      case "/the-artists":
        if (isScreenXLL) {
          console.log("Test Banner isScreenXL:");
          setBackgroudBanner(TestBannerXLL);
        } else if (isScreenXL) {
          console.log("Test Banner isScreenXL:");
          setBackgroudBanner(TestBannerXLL);
        } else if (isScreenLg) {
          console.log("Test Banner isScreenLg:");
          setBackgroudBanner(TestBannerXLL);
        } else if (isMediumScreen) {
          setBackgroudBanner(TestBannerXL);
          console.log("Test Banner isMediumScreen:");
        } else {
          // for tablet
          if (screenWidth >= 768) {
            setBackgroudBanner(TestBannerXL);
          } else {
            setBackgroudBanner(TestBannerMD);
          }
          // setBackgroudBanner(TestBannerMD)
          console.log("Test Banner Mobile:");
        }
        break;
      case "/tickets":
        if (isScreenXXL) {
          console.log("Test Banner isScreenXLL:");
          if (screenHeight <= 1000) {
            setBackgroudBanner(TestBannerXLL);
          } else {
            setBackgroudBanner(TestBannerXL);
          }
        } else if (isScreenXL) {
          console.log("Test Banner isScreenXL:");
          if (screenHeight <= 800) {
            setBackgroudBanner(TestBannerXL);
          } else {
            setBackgroudBanner(TestBannerLG);
          }
        } else if (isScreenLg) {
          console.log("Test Banner isScreenLg:");
          if (screenHeight <= 880) {
            setBackgroudBanner(TestBannerXL);
          } else {
            setBackgroudBanner(TestBannerLG);
          }
        } else if (isMediumScreen) {
          if (screenHeight <= 1100) {
            setBackgroudBanner(TestBannerLG);
          } else {
            setBackgroudBanner(TestBannerMD);
          }
          console.log("Test Banner isMediumScreen:");
        } else {
          // for tablet
          if (screenWidth >= 768) {
            setBackgroudBanner(TestBannerMD);
          } else {
            setBackgroudBanner(TestBannerMD);
          }
          // setBackgroudBanner(TestBanner)
          console.log("Test Banner Mobile:");
        }
        break;
      case "/faq":
        if (isScreenXLL) {
          console.log("Test Banner isScreenXLL:");
          setBackgroudBanner(TestBannerXLL);
        } else if (isScreenXL) {
          console.log("Test Banner isScreenXL:");
          setBackgroudBanner(TestBannerXLL);
        } else if (isScreenLg) {
          console.log("Test Banner isScreenLg:");
          setBackgroudBanner(TestBannerXLL);
        } else if (isMediumScreen) {
          setBackgroudBanner(TestBannerXL);
          console.log("Test Banner isMediumScreen:");
        } else {
          // for tablet
          if (screenWidth >= 768) {
            setBackgroudBanner(TestBannerXL);
          } else {
            setBackgroudBanner(TestBannerMD);
          }
          // setBackgroudBanner(TestBannerMD)
          console.log("Test Banner Mobile:");
        }
        break;
      default:
      // setBgImage(HomeBg); // Default background for unknown routes
    }
  }, [
    pathname,
    isScreenXtraSmall,
    isSmallScreen,
    isMediumScreen,
    isScreenLg,
    isScreenXL,
    isScreenXLL,
    screenHeight,
  ]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const [headerHeight, setHeaderHeight] = useState(0);

  // Function to calculate the height of the header image
  const calculateHeaderHeight = () => {
    const headerImage = document.getElementById("header-image");
    if (headerImage) {
      const headerHeight = headerImage.clientHeight;
      setHeaderHeight(headerHeight);
    }
  };

  // Calculate the header height initially and on window resize
  useEffect(() => {
    calculateHeaderHeight();
    window.addEventListener("resize", calculateHeaderHeight);
    return () => {
      window.removeEventListener("resize", calculateHeaderHeight);
    };
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  const [headerImagesLoaded, setHeaderImagesLoaded] = useState(true);

  const handleHeaderImagesLoad = () => {
    setHeaderImagesLoaded(true);
  };

  return (
    <Box maxWidth="100%" textAlign="center">
      Header
      <Box maxWidth="100%" position="relative">
        {/* {isSmallScreen ? (
         <img
           src="https://bandland.s3.ap-south-1.amazonaws.com/artists/SideLeaves+(1).svg"
           alt="Side image"
           onLoad={handleHeaderImagesLoad}
           style={{
             width: "100%",
             height: "auto",
             objectFit: "cover",
             position: "fixed",
             left: 0,
             top: 20,
             zIndex: 4,
             // marginLeft: "-1%",
             // marginTop: "-5%",
           }}
         />
       ) : (
         <img
           src="https://bandland.s3.ap-south-1.amazonaws.com/mobile/SideLeaves+(4).webp"
           alt="Side image"
           onLoad={handleHeaderImagesLoad}
           style={{
             width: "100%",
             height: "auto",
             objectFit: "cover",
             position: "fixed",
             left: 0,
             top: 20,
             zIndex: 4,
             // marginLeft: "-1%",
             // marginTop: "-5%",
           }}
         />
       )} */}

        {/* {data} */}

        {/* <img
         src={TestBanner}
         alt="Test Banner Image"
         style={{
           // position: "absolute",
           // top: "0", // Position vertically in the middle
           // left: "50%", // Position horizontally in the middle
           // transform: "translate(-50%, -50%)", // Center the image
           zIndex: 1, // Ensure it's on top of SideBorder
           width: "60%", // Adjust the width as needed
           height: "auto", // Maintain aspect ratio
         }}
       /> */}
        <Box
          width="100%"
          position="relative"
          top="2%"
          marginTop={
            pathname == "/" &&
            !isMediumScreen &&
            !isScreenLg &&
            !isScreenXL &&
            !isScreenXLL
              ? "6%"
              : ""
          }
        >
          {/* <img
         src={TestBanner}
         alt="Test Banner Image"
         style={{
           width: "100%",
           height: "auto",
           objectFit: 'cover',
           // marginTop: "80%", // Adjust the marginTop as needed to control the positioning
           // position: "relative"
         }}
       /> */}
          <img
            src={
              "https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/sponsors/bandlandLogo.webp"
            }
            alt="Bandland Bangalore 2024"
            className={`responsive-image ${
              screenWidth > 380 ? "" : "forSmallScreen"
            }`}
            //  width={isSmallScreen? "30vw" :"50vw"}
            style={
              {
                // position: "absolute",
                // top: "0", // Position vertically in the middle
                //  left: "50%", // Position horizontally in the middle
                //  transform: "translate(-50%, 0%)", // Center the image
                // zIndex: 1, // Ensure it's on top of SideBorder
                // width: "60%", // Adjust the width as needed
                // height: "auto", // Maintain aspect ratio
                // border: "2px solid red"
              }
            }
          />

          {/* <img src={BannerLine} className="responsive-image" alt="line-img" style={{
           // width: "60%",
           height: "auto",
           objectFit: 'cover',
           // border: "2px solid red",
           // marginLeft: "-1%",
           marginTop: "-4%",
         }} /> */}
          {/* {!isMediumScreen && !isScreenLg && !isScreenXL && !isScreenXLL ? <img src={BannerLine} className="responsive-image" alt="line-img" style={{
           // width: "60%",
           height: "auto",
           objectFit: 'cover',
           // border: "2px solid red",
           // marginLeft: "-1%",
           marginTop: "-9%",
         }} /> : <></>} */}
          {/* {!isMediumScreen && !isScreenLg && !isScreenXL && !isScreenXLL ? <img src={BannerLine} className="responsive-image" alt="line-img" style={{
           // width: "60%",
           height: "auto",
           objectFit: 'cover',
           // border: "2px solid red",
           // marginLeft: "-1%",
           marginTop:"-9%"
         }} /> : <></>} */}
          {/* <img src={BannerLine} className="responsive-image" alt="line-img" style={{
           // width: "60%",
           height: "auto",
           objectFit: 'cover',
           // border: "2px solid red",
           // marginLeft: "-1%",
           marginTop: "-17%",
         }} /> */}
        </Box>
        {/* <Box border="2px solid yellow" sx={{ width: { xs: "80%", sm: "16px", md: "18px", lg: "20px", xl: "24px", xll: "70%" } }} width="80%" margin="auto"> */}
        {/* https://bandland.s3.ap-south-1.amazonaws.com/artists/Artboard+1.png */}
        {/* https://bandland.s3.ap-south-1.amazonaws.com/artists/gogoDollBanner+(2).svg */}
        {/* <img src="https://bandland.s3.ap-south-1.amazonaws.com/artists/gogoDollBanner+(2).svg" className="gogoDollBanner" alt="img" style={{
           width: "100%",
           height: "auto",
           objectFit: 'cover',
           top: 0,
           // marginLeft: "-1%",
           // marginTop: "45%",
           marginTop: "50%",
         }} /> */}
        {/* <img src="https://bandland.s3.ap-south-1.amazonaws.com/artists/Artboard+1.png" className="gogoDollBanner" alt="img" style={{
           width: "100%",
           height: "auto",
           objectFit: 'cover',
           top: 0,
           // marginLeft: "-1%",
           marginTop: "45%",
         }} /> */}
        {/* </Box> */}
        {/* {pageContent} */}
        {headerImagesLoaded ? (
          <>
            {/* Header content */}
            {/* ... Other header content ... */}
            {pageContent}
          </>
        ) : (
          // Render a custom loading GIF or indicator while images are loading
          <Box
            width="100%"
            height="100vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
      {/* Header buttons */}
      <Box
        position="fixed"
        top="0"
        zIndex="15"
        display="flex"
        sx={{
          height: {
            xs: "67px",
            sm: "70px",
            md: "90px",
            lg: "125px",
            xl: "125px",
          },
          justifyContent: {
            xs: "center",
            sm: "space-between",
            md: "space-between",
            lg: "space-between",
            xl: "space-between",
            xll: "space-between",
            xxl: "space-between",
          },
        }}
        alignItems="center"
        padding="0 49.5px"
        style={{
          backgroundColor: "#F08A53",
          width: "100vw",
          boxSizing: "border-box",
        }}
        // border="1px solid red"
      >
        <span className="hamburgerIcon">
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{
              padding: {
                xs: "0px",
                sm: "5px",
                md: "8px",
                lg: "10px",
                xl: "10px",
              },
              paddingX: {
                xs: "0px",
                sm: "5px",
                md: "8px",
                lg: "10px",
                xl: "20px",
              },
              marginX: "10px",

              visibility: "visible",
            }}
            onClick={handleDrawerToggle}
          >
            <Box
              component="img"
              src="https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/mobile/common/hamburgericon.webp" // Image link
              alt="Menu" // Alt text for accessibility
              sx={{
                width: {
                  xs: "29px",
                  sm: "38px",
                  md: "46px",
                  lg: "56px",
                  xl: "56px",
                  xll: "56px",
                  xxl: "56px",
                },
                // Adjust width
                height: "auto", // Adjust height
              }}
            />
          </IconButton>
        </span>

        {isSmallScreen ? (
          <Link style={{ justifySelf: "center" }} to={"/"}>
            <Button
              className={`font-modesto-condensed btn-text`}
              sx={{
                fontSize: {
                  sm: "33px",
                  md: "35px",
                  lg: "48px",
                  xl: "50px",
                  xll: "50px",
                  xxl: "50px",
                },
                fontFamily: "modesto-condensed",
                color: "black",
              }}
            >
              HOME
            </Button>
          </Link>
        ) : (
          ""
        )}
        {isSmallScreen ? (
          <Link to={"/tickets"}>
            <Button
              className={`font-modesto-condensed btn-text ${
                pathname === "/tickets" && "navActive"
              } `}
              sx={{
                fontSize: {
                  sm: "33px",
                  md: "35px",
                  lg: "48px",
                  xl: "50px",
                  xll: "50px",
                  xxl: "50px",
                },
                fontFamily: "modesto-condensed",
                color: "black",
              }}
            >
              TICKETS
            </Button>
          </Link>
        ) : (
          ""
        )}
      </Box>
      {/* <Box >
        <Footer />
      </Box> */}
      <Drawer
        anchor="bottom" // Change to 'left' for a left-side menu
        open={isDrawerOpen}
        onClose={handleDrawerToggle}
      >
        {/* Add your menu content here */}
        <Paper className="hamburger" style={{ height: "100vh" }}>
          <Hamburger handleDrawerToggle={handleDrawerToggle} />{" "}
        </Paper>
      </Drawer>
    </Box>
  );
};

export default Header;
