
import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import useInView from '../hooks/Lazyloader';
const StyledTypography = ({ 
    variant = 'body1', 
    fontFamily, 
    color = 'white', 
    textShadow = '3px 3px 0px #390c06', 
    textAlign = 'center', 
    sx = {}, 
    children, 
    ...props 
  }) => {

    const [ref, isInView] = useInView({
        threshold: 0.1, // Adjust this value as needed
        triggerOnce: true, // This ensures the card renders only once when it comes into view
      });
      const [shouldRender, setShouldRender] = useState(false);
      useEffect(() => {
        if (isInView) {
          setShouldRender(true);
        }
      }, [isInView]);
    
      if (!shouldRender) {
        return <div ref={ref} style={{ height: "60px", width: "100%" }} />; // Placeholder
      }
    

    return (
      <Typography
        variant={variant}
        fontFamily={fontFamily}
        color={color}
        sx={{
          textShadow,
          textAlign,
          margin: 'auto',
          animation: 'slideUp 0.5s ease-out forwards',
          '@keyframes slideUp': {
            '0%': {
              transform: 'translateY(100%)',
              opacity: 0,
            },
            '100%': {
              transform: 'translateY(0)',
              opacity: 1,
            },
          },
          ...sx
        }}
        {...props}
      >
        {children}
      </Typography>
    );
  };
  
  export default StyledTypography;