import { CircularProgress } from '@mui/material';
import React from 'react';

const Loader = () => {
  return (
    <div style={{width:"100vw", height:"100vh"}} className="loader">
     <CircularProgress margin="auto"  />
    </div>
  );
};

export default Loader;