import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

const SideCurtains = ({ leftImageSrc, rightImageSrc, mobileleft, mobileright }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const commonBigScreenImageSrc = "https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/remaining/common/star.webp";
  const smallScreenImageSrc = "https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/remaining/common/bushes.webp";

  if (!isLargeScreen) {
    return (
      <>
        {mobileleft ? (
          <>
            {/* Left curtain */}
            <img
              src={mobileleft}
              alt="Left curtain"
              style={{
                position: "fixed",
                left: 0,
                top: "0",
                height: "auto",
                width: "22%",
                objectFit: "cover",
                zIndex: 4,
              }}
            />
            
            {/* Right curtain */}
            <img
              src={mobileright}
              alt="Right curtain"
              style={{
                position: "fixed",
                right: 0,
                top: "0",
                height: "auto",
                width: "22%",
                objectFit: "cover",
                zIndex: 4,
              }}
            />
          </>
        ) : (
          <img
            src={smallScreenImageSrc}
            alt="Full width curtain"
            style={{
              position: "fixed",
              left: 0,
              top: 0,
              height: "auto",
              width: "100%",
              pointerEvents: "none",
              zIndex: 4,
            }}
          />
        )}
      </>
    );
  }

  return (
    <>
      {/* Left curtain */}
      <img
        src={leftImageSrc}
        alt="Left curtain"
        style={{
          position: "fixed",
          left: 0,
          top: "10%",
          height: "auto",
          width: "20%",
          objectFit: "cover",
          zIndex: 4,
        }}
      />
      
      {/* Right curtain */}
      <img
        src={rightImageSrc}
        alt="Right curtain"
        style={{
          position: "fixed",
          right: 0,
          top: "75px",
          height: "auto",
          width: "20%",
          objectFit: "cover",
          zIndex: 4,
        }}
      />

      {/* Common big screen image */}
      <img
        src={commonBigScreenImageSrc}
        alt="Common big screen image"
        style={{
          position: "fixed",
          left: 0,
          top: "-7%",
          bottom: "10px",
          height: "auto",
          width: "100%",
          pointerEvents: "none",
          objectFit: "contain",
          zIndex: 5,
        }}
      />
    </>
  );
};

export default SideCurtains;
