import { Alert, Snackbar, SnackbarOrigin } from '@mui/material'
import React from 'react'

function AlertMessage(props) {
  const { snackbar, setSnackbar } = props

  return (
    <div>
      <Snackbar open={snackbar?.open} autoHideDuration={4000} onClose={() => setSnackbar({
        ...snackbar,
        open: false,
      })} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert variant='filled' severity={snackbar?.severity} sx={{ fontSize: { xs: "16px", sm: "20px", md: "20px", lg: "25px", xl: "25px", xll: "25px" }, marginTop: { xs: 5, sm: 7, md: 10, lg: 14, xl: 16, xll: 16 }, display:"flex", justifyContent:"center", alignItems:"center"}} >
          {snackbar?.message}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default AlertMessage
