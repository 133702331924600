import React, { useCallback, useEffect, useState } from "react";
// import "../../styles/Home.css";
import "./ticket.css";
// import TextBanner from "../../images/Home/Text Banner.png";
import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
// import OrangeBand from "../../images/Home/Orange Band2.png";
// import Footer from "../../common/Footer";
import Header from "../../common/Header";
// import Banner from "../../images/testBanner.svg";
// import gogoDollBanner from "../../images/gogoDollBanner.svg";
import Footer from "../../common/Footer/index";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import FAQAccordian from "../../common/FAQAccordion/FAQAccordion";
import { DividerBox } from "../home";
import { Sponsors } from "../../common/Footer/sponsors";
import SideCurtains from "../../common/Header/sidecurtain";
import useInView from "../../hooks/Lazyloader";
import StyledTypography from "../../font/StyledTExt";
// import ticketTextBanner1 from "../../images/ticketTextBanner1.png";
// import ticketTextBanner2 from "../../images/ticketTextBanner2.png";
// import ticketTextBanner3 from "../../images/ticketTextBanner3.png";

const BookNow = ({ link = "https://in.bookmyshow.com/events/bandland-2024/ET00406791"}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  return (
    <Box display="flex" justifyContent="center" mt={4}>
     
      <Button
        variant="outlined" // Use outlined variant to show the border
        href={link}
         target="_blank"
        rel="noopener noreferrer"
        sx={{
          backgroundColor: "#F08A53",
          fontFamily: "texturina",
          color: "white", // Text color
          border: "1px solid",
          borderColor: "#390C06", // Border color
          "&:hover": {
            backgroundColor: "#d07843", // Hover background color
            borderColor: "#390C06", // Keep border color on hover
          },
          padding: {
            xs: screenWidth < 380 ? "4px 18px" : "8px 18px",
            sm: "10px 20px",
            md: "12px 22px",
            lg: "13px 24px",
            xl: "15px 26px",
          },

          marginTop: {
            xs: "0px",
            sm: "10px",
            md: "15px",
            lg: "20px",
            xl: "25px",
          },

          marginBottom: {
            xs: screenWidth < 380 ? "21px" : "35px",
            sm: "70px",
            md: "80px",
            lg: "80px",
            xl: "120px",
          },
          borderRadius: "10px",
          fontSize: {
            xs: "12px",
            sm: "14px",
            md: "17px",
            lg: "20px",
            xl: "22px",
          },
          textShadow:
            " 0.5px 0.5px 0 black,  -0.5px 0.5px 0 black,   0.5px -0.5px 0 black, -0.5px -0.5px 0 black",

          fontWeight: "bold",
        }}
      >
        Book now
      </Button>
    </Box>
  );
};

export const ImageBox = ({ src, alt, pt = true , width={}, pb= true,  marginYaxis= false,  marginY= {}}) => {

 const isSMallestScreen = useMediaQuery("(max-width:391px)");
  
 const [ref, isInView] = useInView({
  threshold: 0.2, // Adjust this value as needed
  triggerOnce: true, // This ensures the card renders only once when it comes into view
});
const [shouldRender, setShouldRender] = useState(false);
useEffect(() => {
  if (isInView) {
    setShouldRender(true);
  }
}, [isInView]);



  useEffect(()=>{

  },[isSMallestScreen])
  if (!shouldRender) {
    return <div ref={ref} style={{ height: "380px", width: "100%" }} />; // Placeholder
  }
  return (
    <Box
      component="img"
      src={src}
      alt={alt}
      sx={{
        width: {
          xs: isSMallestScreen ? width.smallest : width.xs,
          sm: width.sm,
          md: width.md,
          lg: width.lg,
          xl: width.xl,
        },
        margin: "auto",
        paddingTop: {
          xs: pt && isSMallestScreen ? "10px" : "25px",
          sm: pt && "40px",
          md: pt && "40px",
          lg: pt && "40px",
          xl: pt && "130px",
        },
        paddingBottom: {
          xs:  pb&& (isSMallestScreen  ? "0px" : "15px"),
          sm: pb&&"30px",
          md: pb&&"30px",
          lg: pb&&"40px",
          xl:pb&& "40px",
        },
        pointerEvents: "none",
        animation: 'slideUp 1s ease-out forwards',
        '@keyframes slideUp': {
          '0%': {
            transform: 'translateY(100%)',
            opacity: 0,
          },
          '100%': {
            transform: 'translateY(0)',
            opacity: 1,
          },
        },
        ...(marginYaxis && { marginY: marginY })
      }}
      
    />
  );
};

const Tickets = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  

  const isSMallestScreen = useMediaQuery("(max-width:420px)");
  const handleChange = useCallback(
    (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    },

    []
  );

  const generalAdmission = {
    title: "THE CITIZEN (GA) INCLUSIONS",
    points: [
      "Grants general admission to both days of the festival.",
      "Kids aged 0-5 can enter the festival for free.",
      "Kids aged 5+ must buy a GA ticket.",
      "The kid's ticket category must match the guardian's ticket category.",
    ],
  };


  const rockNRollerTicket = {
    title: "Tuborg Zero Soda Rock & Roller Ticket Inclusions",
    points: [
      "Tuborg Zero Soda Rock & Roller Season Pass.",
      "Rock & Roller admission to both days of the festival.",
      "Dedicated zone with elevated deck and view of both stages.",
      "Exclusive (purchasable) FnB Area and toilets in the Rock & Roller area.",
      "Preferred parking near the venue on a first-come, first-served basis.",
      "Kids aged 5+ and older need to buy a Rock & Roller ticket.",
    ],
  };
  
  const aceOfSpadesTicket = {
    title: "ACE OF SPADES TICKET INCLUSIONS",
    points: [
      "Platinum admission to both days of the festival.",
      "Access to the Ace of Spades front of stage pits on both stages.",
      "Exclusive lounge with limited seating, and complimentary snacks and drinks.",
      "Exclusive bars and air-conditioned toilets.",
      "Home-delivered festival kits and dedicated fast access entry lane.",
      "Free parking (with dedicated space) nearest to the venue.",
      "Kids aged 5+ and older need to buy a Ace of Spades ticket."
    ],
  };
  
useEffect(()=>{
  window.scrollTo(0, 0);
},[])
  const pageContent = (
    <Box
      sx={{
        paddingTop: {
          xs: "67px",
          sm: "67px",
          md: "75px",
          lg: "125px",
          xl: "125px",
        },
      }}
      display="flex"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      flexDirection="column"
      width="100%"
      className="  flex-center flex-col align-center text-center ticketTop "
    >
       <SideCurtains
        leftImageSrc="https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/remaining/bushesleft/tickets.webp"
        rightImageSrc="https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/remaining/bushesright/tickets.webp"
        mobileleft={
          "https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/remaining/lefttickets.webp"
        }
        mobileright={
          "https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/remaining/righttickets.webp"
        }
      />
      <Box sx={{ width: "100%" }}>
      <StyledTypography
        variant="h1"
        fontFamily="modesto-condensed"
        sx={{
          fontSize: {
            xs: "30px",
            sm: "38px",
            md: "48px",
            lg: "56px",
            xl: "56px",
          },
          fontWeight: "bold",
          marginBottom: {
            xs: "15px",
            sm: "20px",
            md: "25px",
            lg: "27px",
            xl: "35px",
          },
        }}
      >
        BENGALURU, PUT ONE SCENE!
      </StyledTypography>

      <StyledTypography
        variant="h1"
        fontFamily="modesto-expanded"
        sx={{
          fontSize: {
            xs: "26px",
            sm: "28px",
            md: "30px",
            lg: "34px",
            xl: "36px",
          },
          paddingBottom: {
            xs: "26px",
            sm: "28px",
            md: "30px",
            lg: "34px",
            xl: "36px",
          },
        }}
      >
        BOOK YOUR <br />
        TICKETS NOW!
      </StyledTypography>

        <DividerBox
          imageSrc={
            "https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/remaining/common/dividerline.webp"
          }
        />
 <Box sx={{overflowY:"auto"}} maxHeight={  isSMallestScreen? "950px": ""} >
        {/* // Using General Admission */}
        <ImageBox
          src={
            "https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/remaining/bushesright/citizen.webp"
          }
          alt={"General"}
          width={ {
            smallest:"165px",
            xs:  "179px",
            sm: "280px",
            md: "351px",
            lg: "351px",
            xl: "371px",}}
          pt={false}
        />
               
                <FAQAccordian
          ticketInfo={generalAdmission}
          accordionPanel="generalAdmissionPanel"
          expanded={expanded}
          onChange={handleChange}
        />
        <BookNow />
        {/* // Using Rock 'N Roller Ticket */}
        <ImageBox
          src={
            "https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/remaining/sponsors/Rock&roll.webp"
          }
          
          alt={"Rock"}
          width={ {
            smallest:"280px",
            xs:  "320px",
            sm: "400px",
            md: "620px",
            lg: "620px",
            xl: "620px",}}
            pt={false}
            pb={false}
        />
        <FAQAccordian
          ticketInfo={rockNRollerTicket}
          accordionPanel="rockNRollerPanel"
          expanded={expanded}
          onChange={handleChange}
        />
        <BookNow />
        {/* // Using Ace of Spades Ticket */}
        
        <ImageBox
          src={
            "https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/sponsors/aceofspades.png"
          }
          alt={"ace"}
          width={ {
            smallest:"137px",
            xs:  "141px",
            sm: "280px",
            md: "330px",
            lg: "330px",
            xl: "360px",}}

        />
        <FAQAccordian
          ticketInfo={aceOfSpadesTicket}
          accordionPanel="aceOfSpadesPanel"
          expanded={expanded}
          onChange={handleChange}
          floatAbove={true}
        />
        <BookNow  />





                 </Box>
        



      </Box>

     
      <Sponsors
        svgSrc={
          "https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/finalChanges/ticketSponsors.png"
        }
        mb={4.8} mt={10}
      />

      <Footer />
    </Box>
  );
  return (
    <Box>
      <Helmet>
        <title>Bandland 2024 Get Your Tickets Now</title>
        <link rel="canonical" href="https://www.bandland.in/#/tickets" />
        <meta
      property="og:title"
      content="Bandland 2024 Get Your Tickets Now"
    />
    <meta
      property="og:description"
      content="Join the inner circle to receive all festival updates, from early bird tickets, artist lineup & more. Be the first to know about any exciting news and updates from Bandland Bangalore 2024."
    /> 
     <meta
      name="title"
      content="Bandland 2024 Get Your Tickets Now"
    />
        <meta
          name="description"
          content="Join the inner circle to receive all festival updates, from early bird tickets, artist lineup & more. Be the first to know about any exciting news and updates from Bandland Bangalore 2024.
"
        />
        <meta
          name="keywords"
          content="Bandland Bangalore, Bandland tickets, Bandland 2024, Bandland Bangalore tickets"
        />
         <script data-react-helmet="true" type="application/ld+json">
{
  JSON.stringify({
    "@context": "http://schema.org",
    "@type": "Organization",
    "url": "https://www.bandland.in/",
    "logo": "https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/mobile/common/footerLogo.web",
    "name": "Bandland",
    "sameAs": [

                 
      "https://www.facebook.com/Bandland.in/",
      "https://twitter.com/BandlandIndia/",
      "https://www.youtube.com/@BandlandIndia ",
     "https://www.instagram.com/bandland.in/"
    ],
    "contactPoint": [{
      "@type": "ContactPoint",
      "emaildId": "CustomerExperienceForce@bookmyshow.com",
      "contactType": "customer service",
      "areaServed": "IN",
      "availableLanguage": ["English", "Hindi", "Marathi", "Tamil", "Telugu", "Kannada", "Gujarati", "Punjabi"]
    }] 
  })
}
</script>
{/* website */}
<script data-react-helmet="true" type="application/ld+json">
{
  JSON.stringify({
    "@context": "http://schema.org",
    "@type": "WebSite",
    "name": "BookMyShow",
    "url": "https://www.bandland.in/"
  })
}
</script>
      </Helmet>
      <Header pageContent={pageContent} />
    </Box>
  );
};

export default Tickets;
